import * as React from "react";
import { VFC }    from "react";
import { Helmet } from "react-helmet-async";
import BaseLayout from "../components/layouts/BaseLayout";

const NotFoundPage: VFC = () => (
  <BaseLayout>
    <Helmet>
      <title>404</title>
    </Helmet>
    <h1 style={ {textAlign: "center", height: "80vh"} }>404 お探しのページは見つかりませんでした。</h1>
  </BaseLayout>
);

export default NotFoundPage;
