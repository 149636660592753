import { VFC } from "react";
import "../Request.scss";

const RequestCompleteContent: VFC = () => {
  return (
    <>
      <section className="signup-content">
        <h2>入力いただいたメールアドレスへ資料を送付いたします。 <br/>3営業日以内に届かない場合はお問い合わせください。</h2>
      </section>
    </>
  );
};

export default RequestCompleteContent;
