import React, { VFC }  from "react";
import { Helmet }      from "react-helmet-async";
import BaseLayout      from "../components/layouts/BaseLayout";
import SignUpForm      from "../components/signup/SignUpForm";
import SignUpFormTitle from "../components/signup/SignUpFormTitle";
import SignUpHeader    from "../components/signup/SignUpHeader";

const SignUpPage: VFC = () => {
  return (
    <>
      <BaseLayout>
        <Helmet>
          <title>お申込み・無料お試し｜株式会社MareSpera</title>
          <meta name="description" content="ResKa（レスカ）のお申込み・無料お試しは、こちらのページより必要事項をご記入のうえ、お問い合わせください。"/>
        </Helmet>
        <SignUpHeader/>
        <SignUpFormTitle/>
        <SignUpForm/>
      </BaseLayout>
    </>
  );
};

export default SignUpPage;
