import { VFC }                from "react";
import { Helmet }             from "react-helmet-async";
import BaseLayout             from "../../components/layouts/BaseLayout";
import RequestCompleteContent from "../../components/request/complete/RequestCompleteContent";
import RequestCompleteHeader  from "../../components/request/complete/RequestCompleteHeader";

const RequestCompletePage: VFC = () => {
  return (
    <>
      <BaseLayout>
        <Helmet>
          <title>資料請求受付完了｜株式会社MareSpera</title>
        </Helmet>
        <RequestCompleteHeader/>
        <RequestCompleteContent/>
      </BaseLayout>
    </>
  );
};

export default RequestCompletePage;
