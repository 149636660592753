import axios, { AxiosResponse } from "axios";

interface SignUpApi {
  corporation: {
    name: string,
    phone_number: string
  },
  applicant: {
    last_name: string,
    first_name: string,
    email: string
  },
  contract_plan_id: number,
  offices: { name: string }[],
  send_self_email: boolean
}

export const signup = (value: SignUpApi): Promise<AxiosResponse> => {
  return axios.post(`${process.env.REACT_APP_API_BASE}/signUp`, JSON.stringify(value), {
    headers: {
      "Content-Type": "application/json"
    }
  });
};
