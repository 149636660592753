import { VFC } from "react";

const Environment: VFC = () => {
  return (
    <>
      <section id="sec-environment" className="l-section -pt1530 -pb60120 p3-bg -wh">
        <div className="l-section_content -visible">
          <div className="l-section_content_inner">
            <div id="anchor-environment" className="js-anchor"/>
            <h2 className="p3-headline">
              <div className="ttl -go">推奨環境</div>
            </h2>

            <div className="p0-border -endsdot u-mb80_30"/>

            <div className="p0-envtbl u-mb40_10">
              <div className="p0-envtbl_head -terminal">パソコン</div>

              <div className="p0-envtbl_body">
                <div className="p0-envtbl_body_item">
                  <div className="p0-envtbl_head -os">Windows</div>

                  <div className="p0-envtbl_body_txt">
                    <ul className="p0-list -normal">
                      <li>Microsoft Edge(chromium化後 最新)</li>
                      <li>Google Chrome(最新)</li>
                      <li>Firefox(最新)</li>
                    </ul>
                  </div>
                </div>

                <div className="p0-envtbl_body_item">
                  <div className="p0-envtbl_head -os">Mac</div>

                  <div className="p0-envtbl_body_txt">
                    <ul className="p0-list -normal">
                      <li>Safari(最新)</li>
                      <li>Google Chrome(最新)</li>
                      <li>Firefox(最新)</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="p0-envtbl u-mb40_0">
              <div className="p0-envtbl_head -terminal">タブレット</div>

              <div className="p0-envtbl_body">
                <div className="p0-envtbl_body_item">
                  <div className="p0-envtbl_head -os">Android</div>

                  <div className="p0-envtbl_body_txt">
                    <ul className="p0-list -normal">
                      <li>Google Chrome(最新)</li>
                      <li>Firefox(最新)</li>
                    </ul>
                  </div>
                </div>

                <div className="p0-envtbl_body_item">
                  <div className="p0-envtbl_head -os">iPad</div>

                  <div className="p0-envtbl_body_txt">
                    <ul className="p0-list -normal">
                      <li>Safari(最新)</li>
                      <li>Google Chrome(最新)</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Environment;
