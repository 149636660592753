import React, { VFC } from "react";
import "./Request.scss";

const RequestFormTitle: VFC = () => {
  return (
    <>
      <section className="request-form-title">
        <h5>資料請求フォーム</h5>
        <h2>REQUEST</h2>
      </section>
    </>
  );
};

export default RequestFormTitle;
