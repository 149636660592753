import { VFC }      from "react";
import { Link }     from "react-router-dom";
import "../../assets/css/app.css";
import icon_gt2_ora from "../../assets/images/common/icon_gt2_ora.png";
import icon_gt2_wh  from "../../assets/images/common/icon_gt2_wh.png";

const Navigation: VFC = () => {
  return (
    <>
      <div id="fixBtmNav" className="p1-fixBtmNav js-fixBtmNav">
        <Link
          to="/request"
          target="_blank"
          className="p1-fixBtmNav_item -download hover"
          rel="noopener noreferrer"
        >
          <div className="p1-fixBtmNav_itemInner">
            <span className="p1-fixBtmNav_item_itemInner_txt">資料ダウンロード</span>
            <img
              className="p1-fixBtmNav_itemInner_img"
              src={ icon_gt2_wh }
              alt="資料ダウンロード"
            />
          </div>
        </Link>

        <Link to="/signup" target="_blank" className="p1-fixBtmNav_item -trial hover">
          <div className="p1-fixBtmNav_itemInner">
            <span className="p1-fixBtmNav_item_itemInner_txt">無料お試しはこちら</span>
            <img
              className="p1-fixBtmNav_itemInner_img"
              src={ icon_gt2_ora }
              alt="無料お試しはこちら"
            />
          </div>
        </Link>
      </div>
    </>
  );
};

export default Navigation;
