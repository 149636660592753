import React, { VFC } from "react";
import "./Tokusyo.scss";

const TokusyoHeader: VFC = () => {
  return (
    <>
      <section className="tokusyo-header">
        <h1>特定商取引法に基づく表記</h1>
      </section>
    </>
  );
};

export default TokusyoHeader;
