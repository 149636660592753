import "antd/dist/antd.min.css";
import { createBrowserHistory } from "history";
import LogRocket                from "logrocket";
import React                    from "react";
import ReactDOM                 from "react-dom";
import { HelmetProvider }       from "react-helmet-async";
import { Router }               from "react-router-dom";
import RouterConfiguration      from "./configuration/router/RouterConfiguration";

LogRocket.init("marespera/landingpage");

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Router history={ history }>
      <HelmetProvider>
        <RouterConfiguration/>
      </HelmetProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
