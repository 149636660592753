import React, { VFC } from "react";
import { Helmet }     from "react-helmet-async";
import BaseLayout     from "../components/layouts/BaseLayout";
import TokusyoBody    from "../components/tokusyo/TokusyoBody";
import TokusyoHeader  from "../components/tokusyo/TokusyoHeader";

const TokusyoPage: VFC = () => (
  <>
    <BaseLayout>
      <Helmet>
        <title>特定商取引法に基づく表記｜株式会社MareSpera</title>
      </Helmet>
      <TokusyoHeader/>
      <TokusyoBody/>
    </BaseLayout>
  </>
);

export default TokusyoPage;
