import { VFC }            from "react";
import "../../assets/css/app.css";
import RecordFunctionMovie from "./RecordFunctionMovie";
import styles from "./Function.module.scss";
import sb_01              from "../../assets/images/common/num/sb_01.png";
import sb_02              from "../../assets/images/common/num/sb_02.png";
import function_btm       from "../../assets/images/front/function/function_btm.png";
import function_img1_1    from "../../assets/images/front/function/function_img1_1.jpg";
import function_img1_2    from "../../assets/images/front/function/function_img1_2.jpg";
import function_img1_3    from "../../assets/images/front/function/function_img1_3.jpg";
import function_img2_1    from "../../assets/images/front/function/function_img2_1.jpg";
import function_img2_2    from "../../assets/images/front/function/function_img2_2.jpg";
import function_img2_3    from "../../assets/images/front/function/function_img2_3.jpg";
import function_ttl_deco  from "../../assets/images/front/function/function_ttl_deco.png";
import icon_function1_1   from "../../assets/images/front/function/icon_function1_1.png";
import icon_function1_2   from "../../assets/images/front/function/icon_function1_2.png";
import icon_function1_3   from "../../assets/images/front/function/icon_function1_3.png";
import icon_function2_1   from "../../assets/images/front/function/icon_function2_1.png";
import icon_function2_2   from "../../assets/images/front/function/icon_function2_2.png";
import icon_function2_3   from "../../assets/images/front/function/icon_function2_3.png";
import mockup1            from "../../assets/images/front/function/mockup1.png";
import mockup2            from "../../assets/images/front/function/mockup2.png";
import ttl_en_record      from "../../assets/images/front/function/ttl_en_record.png";
import ttl_en_reservation from "../../assets/images/front/function/ttl_en_reservation.png";

const Function: VFC = () => {
  return (
    <>
      <section id="sec-function" className="l-section p3-bg -wh">
        <div className="l-section_content -visible">
          <div className="l-section_content_inner">
            <div className="p3-img -i01">
              <img
                src={ function_ttl_deco }
                alt=""
              />
            </div>

            <div id="anchor-function" className="js-anchor"/>
            <h2 className="p3-headline">
              <div className="ttl -go">ResKaの機能</div>
            </h2>

            <div className="p0-border -endsdot u-mb15"/>

            <div className="p3-text -lead01 u-mb70_half">
              <p>
                顧客管理に特化したResKaの操作性は抜群です。<span className="break -minpc"/>
                多機能にありがちな難しい操作は一切ありません。
              </p>
            </div>
          </div>


          <div className="p3-headline -function">
            <div className="subttl -function">
              <div className="subttl_line l-section_content_inner">
                <div className="subttl_line1">
                  <div className="subttl_line1_sup">
                    ResKaの機能
                    <img className="-num" src={ sb_01 } alt="01"/>
                  </div>
                </div>
                <div className="subttl_line2">予約</div>
              </div>

              <div className="subttl_mockup">
                <img
                  src={ mockup1 }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p3-headline -function u-mb80_0">
          <div className="subttl_band">
            <div className="subttl_band_en">
              <img
                src={ ttl_en_reservation }
                alt="RESERVATION"
              />
            </div>

            <div className="l-section_content -visible">
              <div className="l-section_content_inner">
                <p className="subttl_band_txt">
                  直感的で効率的な予約システム、<br/>
                  顧客管理の未来を創るResKa。
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="p0-accordion -type1 js-slideBox u-mb40">
          <div className="p0-accordion_head js-slideBtn">
            <div className="p0-accordion_head_ttl">
              <span className="-close">詳細をみる</span>
              <span className="-open">閉じる</span>
            </div>
          </div>

          <div className="p0-accordion_body -close js-slidePanel">
            <div className="l-section_content">
              <div className="p0-txtimgbox">
                <div className="p0-txtimgbox_item -txt">
                  <div className="p0-txtimgbox_item_inner">
                    <div className="p0-txtimgbox_item_icon">
                      <img
                        src={ icon_function1_1 }
                        alt=""
                      />
                    </div>
                    <div className="p0-txtimgbox_item_ttl">シンプルに効率よく</div>
                    <p className="p0-txtimgbox_item_txt p3-text -t03">
                      お客様情報の登録後は、マウスやタッチパネルによる操作のみで予約入力・管理業務を行うことができます。画面を見ながら直感的に操作できるため、はじめてResKaに触れる方でも時間をかけず簡単に予約業務をおこなうことができます。
                    </p>
                  </div>
                </div>

                <div className="p0-txtimgbox_item -img">
                  <img
                    src={ function_img1_1 }
                    alt=""
                  />
                </div>
              </div>

              <div className="p0-txtimgbox -rev">
                <div className="p0-txtimgbox_item -txt">
                  <div className="p0-txtimgbox_item_inner">
                    <div className="p0-txtimgbox_item_icon">
                      <img
                        src={ icon_function1_2 }
                        alt=""
                      />
                    </div>
                    <div className="p0-txtimgbox_item_ttl">
                      単独店舗だけではなく<br/>複数店舗もラクラク管理
                    </div>
                    <p className="p0-txtimgbox_item_txt p3-text -t03">
                      登録した複数の店舗の中から、該当する店舗やスタッフを選択できるため、予約への対応状況をスピーディに確認できます。<br/>
                      管理者として全体を把握できるため、マネージメントに掛かる時間を大幅に削減し、本業や実務に専念できます。
                    </p>
                  </div>
                </div>

                <div className="p0-txtimgbox_item -img">
                  <img
                    src={ function_img1_2 }
                    alt=""
                  />
                </div>
              </div>

              <div className="p0-txtimgbox">
                <div className="p0-txtimgbox_item -txt">
                  <div className="p0-txtimgbox_item_inner">
                    <div className="p0-txtimgbox_item_icon">
                      <img
                        src={ icon_function1_3 }
                        alt=""
                      />
                    </div>
                    <div className="p0-txtimgbox_item_ttl">
                      店舗ごとにさまざまな設定が可能
                    </div>
                    <p className="p0-txtimgbox_item_txt p3-text -t03">
                      予約可能時間帯や一回の予約時間などの設定変更も簡単。臨機応変に予約システムをカスタマイズすることが可能です。
                    </p>
                  </div>
                </div>

                <div className="p0-txtimgbox_item -img">
                  <img
                    src={ function_img1_3 }
                    alt=""
                  />
                </div>
              </div>

            </div>
          </div>
        </div>


        <div className="l-section_content -visible">
          <div className="p3-headline -function">
            <div className="subttl -function">
              <div className="subttl_line l-section_content_inner">
                <div className="subttl_line1">
                  <div className="subttl_line1_sup">
                    ResKaの機能
                    <img
                      className="-num"
                      src={ sb_02 }
                      alt="02"
                    />
                  </div>
                </div>
                <div className="subttl_line2">
                  記録
                </div>
              </div>

              <div className="subttl_mockup">
                <img
                  src={ mockup2 }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p3-headline -function u-mb80_0">
          <div className="subttl_band">
            <div className="subttl_band_en">
              <img src={ ttl_en_record } alt="RESERVATION"/>
            </div>

            <div className="l-section_content -visible">
              <div className="l-section_content_inner">
                <p className="subttl_band_txt">
                  予約管理と顧客情報を同時に記録管理。<br/>
                  オールインワンの予約管理システム、<span className="break -mintb"/>それがResKa。
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="p0-accordion -type1 js-slideBox">
          <div className="p0-accordion_head js-slideBtn">
            <div className="p0-accordion_head_ttl">
              <span className="-close">詳細をみる</span>
              <span className="-open">閉じる</span></div>
          </div>

          <div className="p0-accordion_body -close js-slidePanel">
            <div className="l-section_content">
              <div className="p0-txtimgbox">
                <div className="p0-txtimgbox_item -txt">
                  <div className="p0-txtimgbox_item_inner">
                    <div className="p0-txtimgbox_item_icon">
                      <img
                        src={ icon_function2_1 }
                        alt=""
                      />
                    </div>
                    <div className="p0-txtimgbox_item_ttl">顧客管理の入力項目は自由自在に</div>
                    <p className="p0-txtimgbox_item_txt p3-text -t03">
                      顧客管理において必要な項目は、事業者様によって異なります。さらに事業者様によっては、地域や店舗によって必要な項目が異なる場合も。ResKaなら、パーツを組み合わせてオリジナルの記録シートが作成可能。タップやドラッグで配置も自由に設定できます。
                    </p>
                  </div>
                </div>

                <div className="p0-txtimgbox_item -img">
                  <img
                    src={ function_img2_1 }
                    alt=""
                  />
                </div>
              </div>

              <div className="p0-txtimgbox -rev">
                <div className="p0-txtimgbox_item -txt">
                  <div className="p0-txtimgbox_item_inner">
                    <div className="p0-txtimgbox_item_icon">
                      <img
                        src={ icon_function2_2 }
                        alt=""
                      />
                    </div>
                    <div className="p0-txtimgbox_item_ttl">
                      予約管理と顧客管理を同時に行う
                    </div>
                    <p className="p0-txtimgbox_item_txt p3-text -t03">
                      予約管理とともに顧客管理を一つのシステムで行うことができるため、顧客情報の確認・整理にかける手間を大幅に削減することが可能です。また、記録したい項目やレイアウトを自由に設定できるため、重要なものからわかりやすく閲覧・記入できます。
                    </p>
                  </div>
                </div>

                <div className="p0-txtimgbox_item -img">
                  <img
                    src={ function_img2_2 }
                    alt=""
                  />
                </div>
              </div>

              <div className="p0-txtimgbox">
                <div className="p0-txtimgbox_item -txt">
                  <div className="p0-txtimgbox_item_inner">
                    <div className="p0-txtimgbox_item_icon">
                      <img
                        src={ icon_function2_3 }
                        alt=""
                      />
                    </div>
                    <div className="p0-txtimgbox_item_ttl">使う側のあったらいいなを<br/>機能に盛り込みました</div>
                    <p className="p0-txtimgbox_item_txt p3-text -t03">
                      これまで別の形式で顧客情報の記録システムを利用されていた事業者様にとっても、ResKaではCSV形式のデータでアップロードという方法で顧客情報の取り込みを行えます。
                    </p>
                  </div>
                </div>

                <div className="p0-txtimgbox_item -img">
                  <img
                    src={ function_img2_3 }
                    alt=""
                  />
                </div>
              </div>

              <div className={`p0-txtimgbox ${styles.introduction}`}>
                <RecordFunctionMovie/>
              </div>

            </div>
          </div>
        </div>

        <div className="p3-bg -db">
          <div className="l-section_content -visible">
            <div className="p0-mainsysbox">
              <div className="p0-mainsysbox_imgbox">
                <img
                  src={ function_btm }
                  alt=""
                />
              </div>

              <div className="p0-mainsysbox_txtbox">
                <div className="p0-mainsysbox_txtbox_ttl">
                  ResKaでは、予約システムも、情報記録システムも、どちらもメイン機能として設計しています。
                </div>

                <div className="p0-mainsysbox_txtbox_txt">
                  <p>
                    従来の予約管理システムに、機能不足や、個別に顧客記録管理システムが必要になるなど不便さを感じている事業者様には、ぜひResKaの導入をご検討ください。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Function;
