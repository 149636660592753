import React, { FC } from "react";
import { Link }      from "react-router-dom";
import "./SignUp.scss";

const SignUpFormTitle: FC = () => {
  return (
    <>
      <section className="signup-form-title">
        <h2>REGISTER FORM</h2>
        <p>
          <strong>１ヶ月無料</strong>でResKaをご利用いただけます。<br/>
          無料期間終了後はクレジットカードまたは銀行振込のお手続きのあとに引き続きご利用いただけます。<br/>
          クレジットカードによる決済は無料期間終了後にResKaからお手続きいただけます。<br/>
          詳細は<Link to="/tokusyo">こちら</Link>をご確認ください。
        </p>
      </section>
    </>
  );
};

export default SignUpFormTitle;
