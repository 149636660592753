import React, { VFC } from "react";
import "../../assets/css/app.css";
import logo_marespera from "../../assets/images/common/logo_marespera.png";
import ex_800         from "../../assets/images/front/kv/ex_800.png";
import ex_pc          from "../../assets/images/front/kv/ex_pc.png";
import ex_sp          from "../../assets/images/front/kv/ex_sp.png";
import kv_catchcopy   from "../../assets/images/front/kv/kv_catchcopy.png";
import name_reska     from "../../assets/images/front/kv/name_reska.png";
import no1_1          from "../../assets/images/front/kv/no1_1.png";
import no1_2          from "../../assets/images/front/kv/no1_2.png";
import no1_3          from "../../assets/images/front/kv/no1_3.png";
import no1_award_pc   from "../../assets/images/front/kv/no1_award_pc.png";
import no1_award_sp   from "../../assets/images/front/kv/no1_award_sp.png";
import no1_txt1       from "../../assets/images/front/kv/no1_txt1.png";
import no1_txt2       from "../../assets/images/front/kv/no1_txt2.png";
import person1        from "../../assets/images/front/kv/person1.png";
import person2        from "../../assets/images/front/kv/person2.png";
import person3        from "../../assets/images/front/kv/person3.png";
import person4        from "../../assets/images/front/kv/person4.png";
import person5        from "../../assets/images/front/kv/person5.png";
import recommendation from "../../assets/images/front/kv/recommendation.png";

const Hero: VFC = () => {
  return (
    <>
      <section id="hero" className="p0-hero l-section">
        <div className="l-section_content -visible">
          <div className="l-section_content_inner">
            <h1 className="p1-logo -reska -hero">
              <img
                src={ name_reska }
                alt="ResKa＜レスカ＞"
              />
            </h1>

            <div className="p0-hero_catchcopy">
              <img
                src={ kv_catchcopy }
                alt="重要な顧客データを一元管理。顧客情報から予約管理までレスカひとつで実現できます。"
              />
            </div>

            <div className="p0-hero_mid">
              <div className="p0-hero_mid_people p0-people">
                <div className="p0-people_item">
                  <img
                    src={ person1 }
                    alt=""
                  />
                </div>
                <div className="p0-people_item">
                  <img
                    src={ person2 }
                    alt=""
                  />
                </div>
                <div className="p0-people_item">
                  <img
                    src={ person3 }
                    alt=""
                  />
                </div>
                <div className="p0-people_item">
                  <img
                    src={ person4 }
                    alt=""
                  />
                </div>
                <div className="p0-people_item">
                  <img
                    src={ person5 }
                    alt=""
                  />
                </div>
              </div>

              <div className="p0-hero_mid_recommendation -pc">
                <img
                  src={ recommendation }
                  alt="こんな業種にオススメ！！"
                />
              </div>

              <div className="p0-hero_mid_ex">
                <div className="p0-hero_mid_recommendation -sp">
                  <img
                    src={ recommendation }
                    alt="こんな業種にオススメ！！"
                  />
                </div>
                <div className="p0-hero_mid_ex_img -sp">
                  <img
                    src={ ex_sp }
                    alt="整体クリニック パーソナルトレーニングジム エステティックサロン"
                  />
                </div>
                <div className="p0-hero_mid_ex_img -kvs">
                  <img
                    src={ ex_800 }
                    alt="整体クリニック パーソナルトレーニングジム エステティックサロン"
                  />
                </div>
                <div className="p0-hero_mid_ex_img -pc">
                  <img
                    src={ ex_pc }
                    alt="整体クリニック パーソナルトレーニングジム エステティックサロン"
                  />
                </div>
              </div>
            </div>

            <div id="js-herologo" className="p1-logo -marespera -hero">
              <a
                href="https://marespera.co.jp/"
                className="p1-logo_item hover"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="p1-logo_img"
                  src={ logo_marespera }
                  alt="MareSpera"
                />
              </a>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
