import React, { VFC } from "react";
import "./Tokusyo.scss";

const TokusyoBody: VFC = () => (
  <>
    <section className="tokusyo-body">
      <h3>販売事業者名</h3>
      <p>株式会社MareSpera</p>

      <h3>運営統括責任者名</h3>
      <p>笠原&nbsp;&nbsp;拓</p>

      <h3>所在地</h3>
      <p>東京都立川市曙町2-14-23&nbsp;東財曙町第二ビル&nbsp;2階</p>

      <h3>商品価格(税別)</h3>
      <ul>
        <li>Aプラン<br/>月額25,000円</li>
        <li>Bプラン<br/>月額30.000円</li>
        <li>Cプラン<br/>月額40,000円</li>
      </ul>

      <h3>お支払い方法</h3>
      <ul>
        <li>クレジットカード決済</li>
        <li>銀行振込</li>
      </ul>

      <h3>代金のお支払い時期</h3>
      <ul>
        <li>クレジットカード決済<br/>初回お申込み時、翌月以降毎月月末に請求</li>
        <li>銀行振込<br/>初回お申し込みから翌月末まで、翌月以降毎月月末の請求書発行から翌月末までにお振込み</li>
      </ul>

      <h3>商品代金以外の必要料金</h3>
      <ul>
        <li>インターネット接続に関わる通信回線等の諸費用、消費税等</li>
        <li>銀行振込でのお支払い時に関わる振込手数料等</li>
      </ul>

      <h3>商品の引き渡し時期</h3>
      <p>ご購入手続き後、3営業日以内</p>

      <h3>返品・交換不良品・解約について</h3>
      <p>商品の特性上、購入後の返品・交換はできません。</p>
      <p>解約についてはお問い合わせ窓口からのお問い合わせ後、当月末に解約し、翌月以降の請求を停止いたします。</p>

      <h3>お問い合わせ窓口</h3>
      受付時間 9:00 - 18:00 (土日祝日を除く)
      <ul>
        <li>メールアドレス：info@marespera.co.jp</li>
        <li>電話番号：042-518-9678</li>
      </ul>

    </section>
  </>
);

export default TokusyoBody;
