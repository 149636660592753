import React, { FC, useState } from "react";
import { Link }                from "react-router-dom";
import "../../assets/css/app.css";
import logo_marespera          from "../../assets/images/common/header/logo_marespera.png";
import logo_reska              from "../../assets/images/common/header/logo_reska.png";

const Header: FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <header id="header" className="l-header js-header">
        <div className="l-section_content -visible -wide l-header_inner">
          <div id="gnavfix" className="p1-gnav">
            <div className="p1-logo -reska">
              <Link to="/" className="p1-logo_item hover">
                <img
                  className="p1-logo_img"
                  src={ logo_reska }
                  alt="ResKa＜レスカ＞"
                />
              </Link>
            </div>

            <div
              id="js-drawerOpen"
              className={ open ? "p1-drawer-open js-isopen" : "p1-drawer-open js-isclose" }
              onClick={ () => toggle() }
            >
              <span/>
            </div>

            <div id="drawer" className={ open ? "p1-drawer js-isopen" : "p1-drawer js-isclose" }>
              <div className="p1-logo -reska -splogo">
                <Link to="/" className="p1-logo_item hover">
                  <img
                    className="p1-logo_img"
                    src={ logo_reska }
                    alt="ResKa＜レスカ＞"
                  />
                </Link>
              </div>

              <nav id="gnav" className="p1-gnav_content">
                <ul className="p1-gnav_list">
                  <li className="p1-gnav_item">
                    <a
                      href="./#top"
                      className="p1-gnav_itemInner js-gnavItem hover"
                    >
                      <span>TOP</span>
                    </a>
                  </li>
                  <li className="p1-gnav_item">
                    <a
                      href="./#anchor-feature"
                      className="p1-gnav_itemInner js-gnavItem hover"
                    >
                      <span>特徴</span>
                    </a>
                  </li>
                  <li className="p1-gnav_item">
                    <a
                      href="./#anchor-function"
                      className="p1-gnav_itemInner js-gnavItem hover"
                    >
                      <span>機能</span>
                    </a>
                  </li>
                  <li className="p1-gnav_item">
                    <a
                      href="./#anchor-case"
                      className="p1-gnav_itemInner js-gnavItem hover"
                    >
                      <span>事例</span>
                    </a>
                  </li>
                  <li className="p1-gnav_item">
                    <a
                      href="./#anchor-price"
                      className="p1-gnav_itemInner js-gnavItem hover"
                    >
                      <span>料金</span>
                    </a>
                  </li>
                  <li className="p1-gnav_item">
                    <a
                      href="./#anchor-qa"
                      className="p1-gnav_itemInner js-gnavItem hover"
                    >
                      <span>Q&A</span>
                    </a>
                  </li>
                  <li className="p1-gnav_item">
                    <a
                      href="./#anchor-environment"
                      className="p1-gnav_itemInner js-gnavItem hover"
                    >
                      <span>推奨環境</span>
                    </a>
                  </li>
                  <li className="p1-gnav_item">
                    <a
                      href="https://marespera.co.jp/company"
                      target="_blank"
                      className="p1-gnav_itemInner js-gnavItem hover"
                      rel="noopener noreferrer"
                    >
                      <span>会社情報</span>
                    </a>
                  </li>
                  <li className="p1-gnav_item -pconly">
                    <a
                      href="https://marespera.co.jp/contact"
                      target="_blank"
                      className="p1-gnav_itemInner js-gnavItem hover"
                      rel="noopener noreferrer"
                    >
                      <span>お問い合わせ</span>
                    </a>
                  </li>
                </ul>

                <div className="disp-block-sptb">
                  <div className="p0-flexbtns -center">
                    <div className="p0-btn2">
                      <a
                        href="https://marespera.co.jp/contact/"
                        target="_blank"
                        className="btn_inner hover"
                        rel="noopener noreferrer"
                      >
                        お問い合わせ
                      </a>
                    </div>
                  </div>
                </div>

                <div className="p1-logo -marespera">
                  <a
                    href="https://marespera.co.jp/"
                    target="_blank"
                    className="p1-logo_item hover"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={ logo_marespera }
                      className="p1-logo_img"
                      alt="MareSpera"
                    />
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
