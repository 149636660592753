import React, { FC } from "react";
import "../SignUp.scss";

const SignUpCompleteContent: FC = () => {
  return (
    <>
      <section className="signup-content">
        <h2>３営業日以内に担当からご連絡いたします。</h2>
      </section>
    </>
  );
};

export default SignUpCompleteContent;
