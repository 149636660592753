import { VFC } from "react";
import "../../assets/css/app.css";
import icon_feature1         from "../../assets/images/front/feature/icon_feature1.png";
import icon_feature2         from "../../assets/images/front/feature/icon_feature2.png";
import icon_feature3         from "../../assets/images/front/feature/icon_feature3.png";
import icon_feature4         from "../../assets/images/front/feature/icon_feature4.png";

const Feature: VFC = () => {
  return (
    <>
      <section id="sec-feature" className="l-section -pt2070 -pb2550 p3-bg -wh -feature">
        <div className="l-section_content -visible">
          <div className="l-section_content_inner">
            <div id="anchor-feature" className="js-anchor"/>
            <h2 className="p3-headline">
              <div className="ttl -min -feature">増え続ける顧客情報…まだ紙で続けますか？</div>
            </h2>

            <div className="p0-border -endsdot u-mb10"/>

            <div className="p3-text -lead01 -feature u-mb70_20">
              <p>
                <span className="p3-text -ora">ResKa＜レスカ＞</span>なら、お客様の<span
                className="p3-text -ora">予約と記録を一つのアプリケーションで管理</span>できます!
              </p>
            </div>

            <div className="p0-featurebox">
              <div className="featurebox">
                <div className="img">
                  <img
                    src={ icon_feature1 }
                    alt="特徴1"
                  />
                </div>
                <div className="ttl u-mb10">
                  予約と顧客情報を<br/>ワンストップで管理
                </div>
                <div className="txt">
                  <p className="p3-text -t02">
                    クラウド上に予約＆顧客情報を保存。ひとつのソフトで行うため、作業がスムーズになり、記録ミスの防止や入力の手間が大幅に削減します。
                  </p>
                </div>
              </div>

              <div className="featurebox">
                <div className="img">
                  <img
                    src={ icon_feature2 }
                    alt="特徴2"
                  />
                </div>
                <div className="ttl u-mb10">
                  顧客情報のカスタマイズ<br/>も簡単で使いやすい
                </div>
                <div className="txt">
                  <p className="p3-text -t02">
                    お好きなパーツを配置することで、必要な情報のみを顧客情報として記録することができます。また、写真なども取り込むことが可能です。
                  </p>
                </div>
              </div>

              <div className="featurebox">
                <div className="img">
                  <img
                    src={ icon_feature3 }
                    alt="特徴3"
                  />
                </div>
                <div className="ttl u-mb10">
                  あらゆるデバイスから<br/>アクセスが可能
                </div>
                <div className="txt">
                  <p className="p3-text -t02">
                    PC・スマートフォン・タブレットなど、様々なデバイスからアクセスが可能。外出時でも予約のチェックが可能です。
                  </p>
                </div>
              </div>

              <div className="featurebox">
                <div className="img">
                  <img
                    src={ icon_feature4 }
                    alt="特徴4"
                  />
                </div>
                <div className="ttl u-mb10">
                  万全のセキュリティー<br/>で個人情報も安心
                </div>
                <div className="txt">
                  <p className="p3-text -t02">
                    サーバは世界最高峰のセキュリティシステムを保持するAWS。情報はすべて暗号化され第三者の侵入や改ざんなどの脅威から、守られています。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Feature;
