import React, { FC } from "react";
import "./SignUp.scss";

const SignUpHeader: FC = () => {
  return (
    <>
      <section className="signup-header">
        <h1>ResKa<br/>お申込み</h1>
      </section>
    </>
  );
};

export default SignUpHeader;
