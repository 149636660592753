import React               from "react";
import { Route, Switch }   from "react-router-dom";
import LandingPage         from "../../pages/LandingPage";
import NotFoundPage        from "../../pages/NotFoundPage";
import RequestCompletePage from "../../pages/request/RequestCompletePage";
import RequestPage         from "../../pages/RequestPage";
import SignUpCompletePage  from "../../pages/signup/SignUpCompletePage";
import SignUpPage          from "../../pages/SignUpPage";
import TokusyoPage         from "../../pages/TokusyoPage";
import { useTracking }     from "./GoogleAnalytics";


const RouterConfiguration = () => {
  useTracking("G-4PX5Q273K8");

  return (
    <>
      <Switch>
        <Route exact path="/" component={ LandingPage }/>
        <Route exact path="/signup" component={ SignUpPage }/>
        <Route exact path="/signup/complete" component={ SignUpCompletePage }/>
        <Route exact path="/tokusyo" component={ TokusyoPage }/>
        <Route exact path="/request" component={ RequestPage }/>
        <Route exact path="/request/complete" component={ RequestCompletePage }/>
        <Route exact path="*" component={ NotFoundPage }/>
      </Switch>
    </>
  );
};

export default RouterConfiguration;
