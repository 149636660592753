import { VFC } from "react";
import "../Request.scss";

const RequestCompleteHeader: VFC = () => {
  return (
    <>
      <section className="request-header">
        <h1>資料請求が完了しました</h1>
      </section>
    </>
  );
};

export default RequestCompleteHeader;
