import { VFC } from "react";
import "../../assets/css/app.css";

const Qa: VFC = () => {
  return (
    <>
      <section id="sec-qa" className="l-section -pt1530 -pb2040 p3-bg -wh">
        <div className="l-section_content -visible">
          <div className="l-section_content_inner">
            <div id="anchor-qa" className="js-anchor"/>
            <h2 className="p3-headline">
              <div className="ttl -go">よくあるご質問</div>
            </h2>

            <div className="p0-border -endsdot u-mb110_30"/>

            <div className="p0-accordion -type3 js-slideBox">
              <div className="p0-accordion_head js-slideBtn">
                <div className="p0-accordion_head_ttl">申込後、いつから利用可能ですか。</div>
              </div>

              <div className="p0-accordion_body -close js-slidePanel">
                <div className="p0-accordion_body_inner">
                  <div className="p0-accordion_body_txt">
                    <p className="p3-text -t03">
                      アカウント発行に申込から最大３営業日いただいております。アカウント情報がメールにてお手元に届いた後はすぐにお使いいただけます。
                    </p>
                  </div>
                </div>
              </div>


              <div className="p0-accordion_head js-slideBtn">
                <div className="p0-accordion_head_ttl">試用版はありますか。</div>
              </div>

              <div className="p0-accordion_body -close js-slidePanel">
                <div className="p0-accordion_body_inner">
                  <div className="p0-accordion_body_txt">
                    <p className="p3-text -t03">
                      ございます。無料試用版はお試し申込ページからご連絡ください。<br/>
                      1か月限定となりますが、機能制限はございません。
                    </p>
                  </div>
                </div>
              </div>


              <div className="p0-accordion_head js-slideBtn">
                <div className="p0-accordion_head_ttl">記録テンプレートとはどのようなものですか。</div>
              </div>

              <div className="p0-accordion_body -close js-slidePanel">
                <div className="p0-accordion_body_inner">
                  <div className="p0-accordion_body_txt">
                    <p className="p3-text -t03">
                      事業所様ごとに記録しておく雛形(テンプレート)を作成する機能となります。一度作成すれば、実際のお客様の情報記録に使えます。一から作ることが難しいというお客様向けに弊社で用意した標準テンプレートもございます。そちらから一部変更する等の対応も可能です。<br/>
                      利用イメージの
                      <a
                        href="https://www.youtube.com/watch?v=6fMv_Y3lcDU"
                        target="_blank"
                        className="p3-text -link01 hover"
                        rel="noopener noreferrer"
                      >
                        動画
                      </a>
                      もご用意しております。
                    </p>
                  </div>
                </div>
              </div>


              <div className="p0-accordion_head js-slideBtn">
                <div className="p0-accordion_head_ttl">予約機能のみ、記録機能のみでご提供されていませんか。</div>
              </div>

              <div className="p0-accordion_body -close js-slidePanel">
                <div className="p0-accordion_body_inner">
                  <div className="p0-accordion_body_txt">
                    <p className="p3-text -t03">
                      原則として両機能セットでのご提供とさせていただいております。<br/>
                      単独機能でご利用されたい場合はお問い合わせください。
                    </p>
                  </div>
                </div>
              </div>


              <div className="p0-accordion_head js-slideBtn">
                <div className="p0-accordion_head_ttl">利用者が利用する予約機能はありますか。</div>
              </div>

              <div className="p0-accordion_body -close js-slidePanel">
                <div className="p0-accordion_body_inner">
                  <div className="p0-accordion_body_txt">
                    <p className="p3-text -t03">
                      先日のリリースにより、店舗・事業所様がホームページにて設定いただくことにより利用者様が予約できるようになりました。
                    </p>
                  </div>
                </div>
              </div>


              <div className="p0-accordion_head js-slideBtn">
                <div className="p0-accordion_head_ttl">利用できる決済方法は何がありますか。</div>
              </div>

              <div className="p0-accordion_body -close js-slidePanel">
                <div className="p0-accordion_body_inner">
                  <div className="p0-accordion_body_txt">
                    <p className="p3-text -t03">
                      現在、クレジットカード決済,銀行振込(複数月一括も可能)をご用意させていただいております。
                    </p>
                  </div>
                </div>
              </div>


              <div className="p0-accordion_head js-slideBtn">
                <div className="p0-accordion_head_ttl">請求書の発行はどのように提供されますか。</div>
              </div>

              <div className="p0-accordion_body -close js-slidePanel">
                <div className="p0-accordion_body_inner">
                  <div className="p0-accordion_body_txt">
                    <p className="p3-text -t03">
                      カード決済の場合、個別で発行しておりませんが、必要な場合は別途お問い合わせください。<br/>
                      銀行振込の場合、月末に請求書を登録いただいたメールアドレスに送付いたします。
                    </p>
                  </div>
                </div>
              </div>


              <div className="p0-accordion_head js-slideBtn">
                <div className="p0-accordion_head_ttl">セキュリティに問題はありませんか。</div>
              </div>

              <div className="p0-accordion_body -close js-slidePanel">
                <div className="p0-accordion_body_inner">
                  <div className="p0-accordion_body_txt">
                    <p className="p3-text -t03">
                      弊社ではhttpsによる暗号化通信、世界最高峰のセキュリティを保持するAWS様(Amazon Web
                      Services)の利用、継続的なアップデートを行うことにより、高度なセキュリティレベルを保っております。<br/>
                      なお、事業所様がご入力いただいた情報について弊社が関与することはございませんので、弊社システムへのログインIDとパスワードにつきましては厳重に管理いただけますようお願いいたします。
                    </p>
                  </div>
                </div>
              </div>


              <div className="p0-accordion_head js-slideBtn">
                <div className="p0-accordion_head_ttl">支払いサイトはどのようになりますか。</div>
              </div>

              <div className="p0-accordion_body -close js-slidePanel">
                <div className="p0-accordion_body_inner">
                  <div className="p0-accordion_body_txt">
                    <p className="p3-text -t03">
                      弊社からは毎月の利用料を月末締め、翌月末払いでお願いしております。<br/>
                      カード会社の決済タイミング等により、上記の通りにはならない場合がありますことをご了承ください。<br/>
                      なお、初期契約については３か月間は継続いただける形をお願いしております。<br/>
                      詳細は申込時に承諾いただく利用規約をご確認ください。
                    </p>
                  </div>
                </div>
              </div>


              <div className="p0-accordion_head js-slideBtn">
                <div className="p0-accordion_head_ttl">課金単位はどのようになりますか。</div>
              </div>

              <div className="p0-accordion_body -close js-slidePanel">
                <div className="p0-accordion_body_inner">
                  <div className="p0-accordion_body_txt">
                    <p className="p3-text -t03">
                      1事業所単位とさせていただいております。<br/>
                      例えば、担当者数が３名以下で３事業所(店舗)ある法人様が利用いただく場合、月額利用料は25,000×3の75,000となります。<br/>
                      なお、初期費用は法人様で登録いただく１回のみとなります。使用する事業所を増やす場合は追加になりますので、初期費用は不要となります。
                    </p>
                  </div>
                </div>
              </div>


            </div>

          </div>

        </div>
      </section>
    </>
  );
};

export default Qa;
