import { VFC }  from "react";
import { Link } from "react-router-dom";
import "../../assets/css/app.css";

const Price: VFC = () => {
  return (
    <>
      <section id="sec-price" className="l-section -pb3060 p3-bg -wh">
        <div className="l-section_content -visible">
          <div className="l-section_content_inner">
            <div id="anchor-price" className="js-anchor"/>
            <h2 className="p3-headline">
              <div className="ttl -go">料金</div>
            </h2>

            <div className="p0-border -endsdot u-mb110_30"/>

            <div className="p0-pricebox">
              <div className="p0-pricebox_item">
                <div className="p0-pricebox_item_top">
                  <div className="p0-pricebox_item_plan">トライアル</div>
                  <div className="p0-pricebox_item_price">¥0</div>
                  <div className="p0-pricebox_item_sup">1ヶ月</div>
                </div>

                <div className="p0-pricebox_item_mid">
                  <div className="p0-pricebox_item_users">---</div>
                </div>

                <div className="p0-pricebox_item_btm">
                  <div className="p0-pricebox_item_txt">
                    <p className="u-mb30">
                      ご希望の方はこちらからお申し込みください。
                    </p>

                    <div className="p0-flexbtns -center">
                      <div className="p0-btn1">
                        <Link to="/signup" target="_blank">無料で試す</Link>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className="p0-pricebox_item">
                <div className="p0-pricebox_item_top">
                  <div className="p0-pricebox_item_plan">Plan <span className="-name">A</span></div>
                  <div className="p0-pricebox_item_price">¥27,500</div>
                  <div className="p0-pricebox_item_sup">月額／税込</div>
                </div>

                <div className="p0-pricebox_item_mid">
                  <div className="p0-pricebox_item_users">スタッフ数 1～3名</div>
                </div>

                <div className="p0-pricebox_item_btm">
                  <div className="p0-pricebox_item_txt">
                    <ul className="p0-list -check">
                      <li>スタッフアカウント上限：3</li>
                    </ul>
                  </div>

                </div>

              </div>

              <div className="p0-pricebox_item">
                <div className="p0-pricebox_item_top">
                  <div className="p0-pricebox_item_plan">Plan <span className="-name">B</span></div>
                  <div className="p0-pricebox_item_price">¥33,000</div>
                  <div className="p0-pricebox_item_sup">月額／税込</div>
                </div>

                <div className="p0-pricebox_item_mid">
                  <div className="p0-pricebox_item_users">スタッフ数 4～10名</div>
                </div>

                <div className="p0-pricebox_item_btm">
                  <div className="p0-pricebox_item_txt">
                    <ul className="p0-list -check">
                      <li>スタッフアカウント上限：10</li>
                    </ul>
                  </div>

                </div>

              </div>

              <div className="p0-pricebox_item">
                <div className="p0-pricebox_item_top">
                  <div className="p0-pricebox_item_plan">Plan <span className="-name">C</span></div>
                  <div className="p0-pricebox_item_price">¥44,000〜</div>
                  <div className="p0-pricebox_item_sup">月額／税込</div>
                </div>

                <div className="p0-pricebox_item_mid">
                  <div className="p0-pricebox_item_users">スタッフ数 11名以上</div>
                </div>

                <div className="p0-pricebox_item_btm">
                  <div className="p0-pricebox_item_txt">
                    <ul className="p0-list -check">
                      <li>スタッフアカウント上限：11〜</li>
                    </ul>
                    <p className="p3-text -t05">
                      ※詳細はお問い合わせください
                    </p>
                  </div>

                </div>

              </div>

            </div>
          </div>


        </div>
      </section>
    </>
  );
};

export default Price;
