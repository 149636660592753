import { SendOutlined }              from "@ant-design/icons";
import { Button, Form, Input, Spin } from "antd";
import React, { useState, VFC }      from "react";
import { useHistory }                from "react-router-dom";
import "./Request.scss";

type RequestFormError = {
  target: string;
};

const RequestForm: VFC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [corporationName, setCorporationName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [errors, setErrors] = useState<RequestFormError[]>([]);
  const history = useHistory();

  const validation = () => {
    const validations: RequestFormError[] = [];
    if (!corporationName || corporationName === "") validations.push({target: "corporation_name"});
    if (!lastName || lastName === "") validations.push({target: "last_name"});
    if (!firstName || firstName === "") validations.push({target: "first_name"});
    if (!email || email === "") validations.push({target: "email"});
    setErrors(validations);

    if (validations.length === 0) {
      setLoading(true);
      fetch(`https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&oid=00D7F000003hL2l&company=${ corporationName }&email=${ email }&last_name=${ lastName }&first_name=${ firstName }&phone=${ phoneNumber }&lead_source=資料請求`, {
        method: "POST",
        mode: "no-cors",
      })
        .then(() => history.push("/request/complete"))
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <Spin spinning={ loading }>
        <Form
          layout="vertical"
          className="request-form"
        >
          <Form.Item
            className="request-form-corporation"
            label="法人名"
            colon={ false }
            validateStatus={ errors.find(value => value.target === "corporation_name") ? "error" : undefined }
            help={ errors.find(value => value.target === "corporation_name") ? "必須項目です。" : undefined }
            required
          >
            <Input
              value={ corporationName }
              onChange={ event => setCorporationName(event.target.value) }
            />
          </Form.Item>

          <Form.Item
            className="request-form-email"
            label="ご担当者さまメールアドレス"
            colon={ false }
            validateStatus={ errors.find(value => value.target === "email") ? "error" : undefined }
            help={ errors.find(value => value.target === "email") ? "必須項目です。" : undefined }
            required
          >
            <Input
              value={ email }
              onChange={ event => setEmail(event.target.value) }
            />
          </Form.Item>

          <Form.Item
            label="ご担当者さま氏名"
            colon={ false }
            required
          >
            <Form.Item
              className="request-form-name"
              style={ {marginRight: "1rem"} }
              label="姓"
              colon={ false }
              validateStatus={ errors.find(value => value.target === "last_name") ? "error" : undefined }
              help={ errors.find(value => value.target === "last_name") ? "必須項目です。" : undefined }
            >
              <Input
                value={ lastName }
                onChange={ event => setLastName(event.target.value) }
              />
            </Form.Item>
            <Form.Item
              className="request-form-name"
              label="名"
              colon={ false }
              validateStatus={ errors.find(value => value.target === "first_name") ? "error" : undefined }
              help={ errors.find(value => value.target === "first_name") ? "必須項目です。" : undefined }
            >
              <Input
                value={ firstName }
                onChange={ event => setFirstName(event.target.value) }
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            className="request-form-phone"
            label="電話番号"
            colon={ false }
          >
            <Input
              value={ phoneNumber }
              onChange={ event => setPhoneNumber(event.target.value) }
              maxLength={ 20 }
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              onClick={ () => validation() }
            >
              <SendOutlined/>送信
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default RequestForm;
