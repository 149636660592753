import { Layout } from "antd";
import "antd/dist/antd.min.css";
import * as React from "react";
import { FC }     from "react";
import Footer     from "../footer/Footer";
import "./BaseLayout.scss";

const BaseLayout: FC = ({children}) => (
  <Layout>
    <Layout.Content className="base-content">
      { children }
    </Layout.Content>
    <Footer/>
  </Layout>
);

export default BaseLayout;
