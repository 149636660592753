import * as React  from "react";
import { VFC }     from "react";
import { Helmet }  from "react-helmet-async";
import "../assets/css/app.css";
import ogp         from "../assets/images/common/ogp.png";
import Case        from "../components/case/Case";
import Environment from "../components/environment/Environment";
import Feature     from "../components/feature/Feature";
import Footer      from "../components/footer/Footer";
import Function    from "../components/function/Function";
import Header      from "../components/header/Header";
import Hero        from "../components/hero/Hero";
import Navigation  from "../components/navigation/Navigation";
import Price       from "../components/price/Price";
import Qa          from "../components/qa/Qa";

const LandingPage: VFC = () => {
  return (
    <>
      <Helmet>
        <title>ワンストップで顧客管理できる予約管理システム「ResKa（レスカ）」</title>
        <meta name="keywords" content=""/>
        <meta
          name="description"
          content="ResKa（レスカ）は予約管理と顧客管理をワンストップでできる予約管理システムです。整体クリニック、エステサロン、パーソナルトレーニングジムなど、サービス予約・顧客管理が必要なお客様にご活用いただいています。"
        />
        <meta property="og:locale" content="ja_JP"/>
        <meta property="og:site_name" content="ワンストップで顧客管理できる予約管理システム「ResKa（レスカ）」"/>
        <meta property="og:type" content="article"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="630"/>
        <meta property="og:url" content="https://reska.marespera.co.jp/"/>
        <meta property="og:image" content={ ogp }/>
        <meta property="og:title" content="ワンストップで顧客管理できる予約管理システム「ResKa（レスカ）」"/>
        <meta
          property="og:description"
          content="ResKa（レスカ）は予約管理と顧客管理をワンストップでできる予約管理システムです。整体クリニック、エステサロン、パーソナルトレーニングジムなど、サービス予約・顧客管理が必要なお客様にご活用いただいています。"
        />

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content="@info_marespera"/>
        <link rel="canonical" href="https://reska.marespera.co.jp/service/"/>
      </Helmet>
      <Header/>
      <Hero/>
      <Navigation/>
      <Feature/>
      <Function/>
      <Case/>
      <Price/>
      <Qa/>
      <Environment/>
      <Footer/>
    </>
  );
};

export default LandingPage;
