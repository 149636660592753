import { FC } from "react";
import styles from "./RecordFunctionMovie.module.scss";

const RecordFunctionMovie: FC = () => {
  return (
    <>
      <div className={styles.movie}>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/6fMv_Y3lcDU"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className={styles.player}
        />
      </div>
    </>
  );
};

export default RecordFunctionMovie;
