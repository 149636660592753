import { VFC }   from "react";
import Slider    from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../../assets/css/app.css";
import sb_01     from "../../assets/images/common/num/sb_01.png";
import sb_02     from "../../assets/images/common/num/sb_02.png";
import sb_03     from "../../assets/images/common/num/sb_03.png";
import case1_img from "../../assets/images/front/case/case1_img.jpg";
import case2_img from "../../assets/images/front/case/case2_img.jpg";
import case3_img from "../../assets/images/front/case/case3_img.jpg";

const Case: VFC = () => {
  return (
    <>
      <section id="sec-case" className="l-section -pt3570 p3-bg -wh">
        <div className="l-section_content -visible">
          <div className="l-section_content_inner">
            <div id="anchor-case" className="js-anchor"/>
            <h2 className="p3-headline">
              <div className="ttl -go">活用事例</div>
            </h2>

            <div className="p0-border -endsdot u-mb80_30"/>

            <div className="disp-block-pc u-mb70">
              <Slider
                dots
                autoplay
                infinite
                slidesToShow={ 1 }
                slidesToScroll={ 1 }
                autoplaySpeed={ 10000 }
                arrows
              >
                <div className="p0-slideshow_item">
                  <div className="p0-case -pc">
                    <div className="p3-headline -case">
                      <div className="subttl">
                        <div className="subttl_line">
                          <div className="subttl_line1">
                            <div className="subttl_line1_sup">
                              活用事例
                              <img
                                className="-num"
                                src={ sb_01 }
                                alt="01"
                              />
                            </div>
                          </div>
                          <div className="subttl_line2">整体クリニックでの使用事例</div>
                        </div>
                      </div>
                    </div>

                    <div className="p3-text -t04 u-mb20">
                      <p className="">
                        予約管理とカルテの記録を一括管理。業務効率が飛躍的に上がり本業に取り組む時間が増えました。
                      </p>
                    </div>

                    <div className="p0-case_txt p3-text -t01">
                      <img
                        className=""
                        src={ case1_img }
                        alt="ケース1"
                      />

                      <p className="u-mb25">
                        地方で整体クリニックを開業しています。これまでは予約管理をパソコンでおこないながら、お客様の記録は従来通り紙カルテに記載しておりました。<br/>
                        過去に別の予約ソフトを使用していたことがあるのですが、スタッフが使用に慣れることができなかったため従来の方法に戻したことがあります。
                      </p>
                      <p className="u-mb25">
                          <span className="p3-text -t01 -bold -blue">
                            しかし、ResKaを試しに使用してみて、操作性が良く簡単に予約を入力できるとスタッフから好評であったので導入しました。
                          </span>
                      </p>
                      <p className="">
                        またこれまでは予約とカルテ記録を別々でおこなっていたのですが、このResKaは予約管理とカルテ記録による顧客情報の管理機能がひとつになっており、一台のパソコンやタブレットからお客様の状態や次回来院日まで確認できるので業務に手間がかからなくなり、そのぶんお客様への対応に力を入れられるようになりました。<br/>
                        <span className="p3-text -t01 -bold -blue">
                          予約を効率的に管理できるのでお客様からも予約をするのに時間がかからなくなったという評判を頂いており、待合時間の改善にも繋がったと思っています。
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="p0-slideshow_item">
                  <div className="p0-case -pc">
                    <div className="p3-headline -case">
                      <div className="subttl">
                        <div className="subttl_line">
                          <div className="subttl_line1">
                            <div className="subttl_line1_sup">
                              活用事例
                              <img
                                className="-num"
                                src={ sb_02 }
                                alt="02"
                              />
                            </div>
                          </div>
                          <div className="subttl_line2">エステサロンでの使用事例</div>
                        </div>
                      </div>
                    </div>

                    <div className="p3-text -t04 u-mb20">
                      <p className="">
                        ResKaは多店舗展開するショップの強い味方。店舗別に個別設定が可能な上、一元管理でグループ全体の傾向の把握が可能になりました。
                      </p>
                    </div>

                    <div className="p0-case_txt p3-text -t01">
                      <img
                        className=""
                        src={ case2_img }
                        alt="ケース2"
                      />

                      <p className="u-mb25">
                        都内にて十数件のエステサロンを経営する立場として、店舗ごとに予約管理を確認できるシステムと整理しやすい緻密な顧客情報記録システムが必要でした。<br/>
                        そこで、そのふたつがセットになったResKaの利用を決めました。
                      </p>
                      <p className="u-mb25">
                          <span className="p3-text -t01 -bold -blue">
                            導入したところ、予約システムとしてタッチパネルで予約できて見た目もわかりやすく、これまでより予約確認で生じていた無駄な時間が少なくなりました。
                          </span>
                      </p>
                      <p className="">
                        また同じサロンでも店舗によって設備などの違いがあるので、店舗ごとに施術メニューや料金システムに多少の違いがあります。ですがResKaの情報記録システムは、店舗ごとに情報の項目や画面レイアウトを、かなり細かいところまで自由に設定できます。<br/>
                        <span className="p3-text -t01 -bold -blue">
                          そのため店舗ごとにお客様がよく利用される施術やコース、また利用が多い時間帯を把握できて、各店舗別に、よりお客様がご利用しやすいシステムを導入するなど、細かい経営方針の決定にもたいへん役に立っております。
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="p0-slideshow_item">
                  <div className="p0-case -pc">
                    <div className="p3-headline -case">
                      <div className="subttl">
                        <div className="subttl_line">
                          <div className="subttl_line1">
                            <div className="subttl_line1_sup">
                              活用事例
                              <img
                                className="-num"
                                src={ sb_03 }
                                alt="03"
                              />
                            </div>
                          </div>
                          <div className="subttl_line2">パーソナルトレーニングジムでの使用事例</div>
                        </div>
                      </div>
                    </div>

                    <div className="p3-text -t04 u-mb20">
                      <p className="">
                        個々のお客様の予約傾向やトレーニング状況の把握はパーソナルトレーニングジムには必須です。まさに打ってつけのシステムでした。
                      </p>
                    </div>

                    <div className="p0-case_txt p3-text -t01">
                      <img
                        className=""
                        src={ case3_img }
                        alt="ケース3"
                      />

                      <p className="u-mb25">
                        トレーナーとしてパーソナルトレーニングジムを経営する者です。当ジムは完全予約制で、お客様一人一人にマンツーマンのトレーナーがつくため、各自が使いやすい予約システムと、お客様の状況をいつでも把握できる情報記録システムが必須でした。そこでこちらのResKaを導入しました。
                      </p>
                      <p className="u-mb25">
                          <span className="p3-text -t01 -bold -blue">
                            タブレットひとつでお客様の予約状況と細かな情報を確認できるので当ジムのトレーナーたちから好評を得ています。
                          </span>
                      </p>
                      <p className="">
                        またジム経営者の立場としても、お客様情報の項目やレイアウトを好きに設定できるので、このお客様は何曜日の何時からの予約が多いなど、お客様各個人の予約傾向から、予約が重なりやすい曜日や時間帯まで、一目で把握できるようになりました。<br/>
                        <span className="p3-text -t01 -bold -blue">
                          そのため、トレーナーのシフト調整や、ジム機器の起動や整備など効率的に行いやすくなり、これまで悩みの種だったさまざまな無駄が軽減できました。
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>

            <div className="disp-block-sptb">
              <div className="p0-case -sp">
                <div className="p3-headline -case">
                  <div className="subttl">
                    <div className="subttl_line">
                      <div className="subttl_line1">
                        <div className="subttl_line1_sup">
                          活用事例
                          <img
                            className="-num"
                            src={ sb_01 }
                            alt="01"
                          />
                        </div>
                      </div>
                      <div className="subttl_line2">整体クリニックでの使用事例</div>
                    </div>
                  </div>
                </div>

                <div className="p3-text -t04 u-mb20">
                  <p className="">
                    予約管理とカルテの記録を一括管理。業務効率が飛躍的に上がり本業に取り組む時間が増えました。
                  </p>
                </div>

                <div className="p0-case_img">
                  <img
                    className=""
                    src={ case1_img }
                    alt="ケース1"
                  />
                </div>

                <div className="p0-accordion -type2 js-slideBox">
                  <div className="p0-accordion_head js-slideBtn">
                    <div className="p0-accordion_head_ttl">
                      <span className="-close">詳細をみる</span>
                      <span className="-open">閉じる</span>
                    </div>
                  </div>

                  <div className="p0-accordion_body -close js-slidePanel u-mb5">
                    <div className="p0-case_txt p3-text -t01">

                      <p className="u-mb15">
                        地方で整体クリニックを開業しています。これまでは予約管理をパソコンでおこないながら、お客様の記録は従来通り紙カルテに記載しておりました。<br/>
                        過去に別の予約ソフトを使用していたことがあるのですが、スタッフが使用に慣れることができなかったため従来の方法に戻したことがあります。
                      </p>
                      <p className="u-mb15">
                        <span className="p3-text -t01 -bold -blue">
                          しかし、ResKaを試しに使用してみて、操作性が良く簡単に予約を入力できるとスタッフから好評であったので導入しました
                        </span>
                      </p>
                      <p>
                        またこれまでは予約とカルテ記録を別々でおこなっていたのですが、このResKaは予約管理とカルテ記録による顧客情報の管理機能がひとつになっており、一台のパソコンやタブレットからお客様の状態や次回来院日まで確認できるので業務に手間がかからなくなり、そのぶんお客様への対応に力を入れられるようになりました。<br/>
                        <span className="p3-text -t01 -bold -blue">
                          予約を効率的に管理できるのでお客様からも予約をするのに時間がかからなくなったという評判を頂いており、待合時間の改善にも繋がったと思っています。
                        </span>
                      </p>
                    </div>
                  </div>

                </div>
              </div>

              <div className="p0-case -sp">
                <div className="p3-headline -case">
                  <div className="subttl">
                    <div className="subttl_line">
                      <div className="subttl_line1">
                        <div className="subttl_line1_sup">
                          活用事例
                          <img
                            className="-num"
                            src={ sb_02 }
                            alt="02"
                          />
                        </div>
                      </div>
                      <div className="subttl_line2">エステサロンでの使用事例</div>
                    </div>
                  </div>
                </div>

                <div className="p3-text -t04 u-mb20">
                  <p className="">
                    ResKaは多店舗展開するショップの強い味方。店舗別に個別設定が可能な上、一元管理でグループ全体の傾向の把握が可能になりました。
                  </p>
                </div>

                <div className="p0-case_img">
                  <img
                    className=""
                    src={ case2_img }
                    alt="ケース2"
                  />
                </div>

                <div className="p0-accordion -type2 js-slideBox">
                  <div className="p0-accordion_head js-slideBtn">
                    <div className="p0-accordion_head_ttl">
                      <span className="-close">詳細をみる</span>
                      <span className="-open">閉じる</span></div>
                  </div>

                  <div className="p0-accordion_body -close js-slidePanel u-mb5">
                    <div className="p0-case_txt p3-text -t01">

                      <p className="u-mb15">
                        都内にて十数件のエステサロンを経営する立場として、店舗ごとに予約管理を確認できるシステムと整理しやすい緻密な顧客情報記録システムが必要でした。<br/>
                        そこで、そのふたつがセットになったResKaの利用を決めました。
                      </p>
                      <p className="u-mb15">
                        <span className="p3-text -t01 -bold -blue">
                          導入したところ、予約システムとしてタッチパネルで予約できて見た目もわかりやすく、これまでより予約確認で生じていた無駄な時間が少なくなりました。
                        </span>
                      </p>
                      <p>
                        また同じサロンでも店舗によって設備などの違いがあるので、店舗ごとに施術メニューや料金システムに多少の違いがあります。ですがResKaの情報記録システムは、店舗ごとに情報の項目や画面レイアウトを、かなり細かいところまで自由に設定できます。<br/>
                        <span className="p3-text -t01 -bold -blue">
                          そのため店舗ごとにお客様がよく利用される施術やコース、また利用が多い時間帯を把握できて、各店舗別に、よりお客様がご利用しやすいシステムを導入するなど、細かい経営方針の決定にもたいへん役に立っております。
                        </span>
                      </p>
                    </div>
                  </div>

                </div>
              </div>


              <div className="p0-case -sp">
                <div className="p3-headline -case">
                  <div className="subttl">
                    <div className="subttl_line">
                      <div className="subttl_line1">
                        <div className="subttl_line1_sup">
                          活用事例
                          <img
                            className="-num"
                            src={ sb_03 }
                            alt="03"
                          />
                        </div>
                      </div>
                      <div className="subttl_line2">パーソナルトレーニングジムでの使用事例</div>
                    </div>
                  </div>
                </div>

                <div className="p3-text -t04 u-mb20">
                  <p className="">
                    個々のお客様の予約傾向やトレーニング状況の把握はパーソナルトレーニングジムには必須です。まさに打ってつけのシステムでした。
                  </p>
                </div>

                <div className="p0-case_img">
                  <img
                    className=""
                    src={ case3_img }
                    alt="ケース3"
                  />
                </div>

                <div className="p0-accordion -type2 js-slideBox">
                  <div className="p0-accordion_head js-slideBtn">
                    <div className="p0-accordion_head_ttl">
                      <span className="-close">詳細をみる</span>
                      <span className="-open">閉じる</span></div>
                  </div>

                  <div className="p0-accordion_body -close js-slidePanel u-mb5">
                    <div className="p0-case_txt p3-text -t01">

                      <p className="u-mb15">
                        トレーナーとしてパーソナルトレーニングジムを経営する者です。当ジムは完全予約制で、お客様一人一人にマンツーマンのトレーナーがつくため、各自が使いやすい予約システムと、お客様の状況をいつでも把握できる情報記録システムが必須でした。そこでこちらのResKaを導入しました。
                      </p>
                      <p className="u-mb15">
                        <span className="p3-text -t01 -bold -blue">
                          タブレットひとつでお客様の予約状況と細かな情報を確認できるので当ジムのトレーナーたちから好評を得ています。
                        </span>
                      </p>
                      <p>
                        またジム経営者の立場としても、お客様情報の項目やレイアウトを好きに設定できるので、このお客様は何曜日の何時からの予約が多いなど、お客様各個人の予約傾向から、予約が重なりやすい曜日や時間帯まで、一目で把握できるようになりました。<br/>
                        <span className="p3-text -t01 -bold -blue">
                          そのため、トレーナーのシフト調整や、ジム機器の起動や整備など効率的に行いやすくなり、これまで悩みの種だったさまざまな無駄が軽減できました。
                        </span>
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>


          </div>
        </div>
      </section>
    </>
  );
};

export default Case;
