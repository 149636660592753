import React, { FC }  from "react";
import { Link }       from "react-router-dom";
import "../../assets/css/app.css";
import catchcopy      from "../../assets/images/common/footer/catchcopy.png";
import icon_facebook  from "../../assets/images/common/footer/icon_facebook.png";
import icon_instagram from "../../assets/images/common/footer/icon_instagram.png";
import icon_twitter   from "../../assets/images/common/footer/icon_twitter.png";
import people         from "../../assets/images/common/footer/people.png";
import logo_marespera from "../../assets/images/common/logo_marespera.png";

const Footer: FC = () => {
  return (
    <>
      <footer className="l-footer l-section">
        <div className="l-section_content -wide -visible">
          <div className="l-footer_inner">
            <div className="p2-footer_box1">
              <div className="p2-ftinfo">
                <div className="p2-ftinfo_logo">
                  <a
                    href="https://marespera.co.jp/"
                    target="_blank"
                    className="hover"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={ logo_marespera }
                      alt="MareSpera"
                    />
                  </a>
                </div>

                <div className="p2-ftinfo_txt">
                  <div className="p2-ftinfo_catchcopy">
                    <img
                      src={ catchcopy }
                      alt="関わる人すべてがプラスに"
                    />
                  </div>

                  <a
                    href="https://marespera.co.jp/"
                    target="_blank"
                    className="p2-ftinfo_companyname hover"
                    rel="noopener noreferrer"
                  >
                    <div className="-kana">マレスペラ</div>
                    <div className="-name">株式会社MareSpera</div>
                  </a>

                  <div className="p2-ftinfo_address">東京都立川市曙町2-14-23 東財曙町第二ビル2F</div>
                </div>

                <div className="p2-ftinfo_people">
                  <img
                    src={ people }
                    alt=""
                  />
                </div>
              </div>
            </div>

            <section>
              <ul>
                <li>
                  <a
                    href="https://s3-ap-northeast-1.amazonaws.com/marespera-corporate-site/terms-of-service/TermsOfService.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    利用規約
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3-ap-northeast-1.amazonaws.com/marespera-corporate-site/privacy-policy/PrivacyPolicy.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <Link
                    to="/tokusyo"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    特定商取引法に基づく表記
                  </Link>
                </li>
                <li>
                  <a
                    href="https://marespera.co.jp/company/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    会社情報
                  </a>
                </li>
              </ul>
            </section>

            <div className="p2-footer_box2">
              <div className="p2-sns">
                <a
                  href="https://www.facebook.com/infomarespera/"
                  target="_blank"
                  className="p2-sns_item hover"
                  rel="noopener noreferrer"
                >
                  <img
                    src={ icon_facebook }
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://twitter.com/info_marespera"
                  target="_blank"
                  className="p2-sns_item hover"
                  rel="noopener noreferrer"
                >
                  <img
                    src={ icon_twitter }
                    alt="Twitter"
                  />
                </a>
                <a
                  href="https://www.instagram.com/maresperacoltd/"
                  target="_blank"
                  className="p2-sns_item hover"
                  rel="noopener noreferrer"
                >
                  <img
                    src={ icon_instagram }
                    alt="Instagram"
                  />
                </a>
              </div>

              <p className="p2-copyright">Copyright&nbsp;&copy;&nbsp;2021&nbsp;MareSpera</p>
            </div>
          </div>
        </div>

      </footer>
      {/*<section className="footer">*/ }
      {/*  <nav className="footer-nav">*/ }
      {/*    <ul>*/ }
      {/*      <li>*/ }
      {/*        <a*/ }
      {/*          href="https://s3-ap-northeast-1.amazonaws.com/marespera-corporate-site/terms-of-service/TermsOfService.pdf"*/ }
      {/*          target="_blank"*/ }
      {/*          rel="noopener noreferrer"*/ }
      {/*        >*/ }
      {/*          利用規約*/ }
      {/*        </a>*/ }
      {/*      </li>*/ }
      {/*      <li>*/ }
      {/*        <a*/ }
      {/*          href="https://s3-ap-northeast-1.amazonaws.com/marespera-corporate-site/privacy-policy/PrivacyPolicy.pdf"*/ }
      {/*          target="_blank"*/ }
      {/*          rel="noopener noreferrer"*/ }
      {/*        >*/ }
      {/*          プライバシーポリシー*/ }
      {/*        </a>*/ }
      {/*      </li>*/ }
      {/*      <li>*/ }
      {/*        <Link to="/tokusyo">特定商取引法に基づく表記</Link>*/ }
      {/*      </li>*/ }
      {/*      <li>*/ }
      {/*        <a*/ }
      {/*          href="https://marespera.co.jp/company/"*/ }
      {/*          target="_blank"*/ }
      {/*          rel="noopener noreferrer"*/ }
      {/*        >*/ }
      {/*          会社情報*/ }
      {/*        </a>*/ }
      {/*      </li>*/ }
      {/*    </ul>*/ }
      {/*  </nav>*/ }

      {/*  <div className="footer-corporation">*/ }
      {/*    Copyright © { new Date().getFullYear() } <a href="https://marespera.co.jp">MareSpera, Inc</a> All rights reserved.*/ }
      {/*  </div>*/ }
      {/*</section>*/ }
    </>
  );
};

export default Footer;
