import React, { VFC } from "react";
import "../SignUp.scss";

const SignUpCompleteHeader: VFC = () => {
  return (
    <>
      <section className="signup-header">
        <h1>無料お試し<br/>お申込みが完了しました</h1>
      </section>
    </>
  );
};

export default SignUpCompleteHeader;
