import React, { VFC }        from "react";
import { Helmet }            from "react-helmet-async";
import BaseLayout            from "../../components/layouts/BaseLayout";
import SignUpCompleteContent from "../../components/signup/complete/SignUpCompleteContent";
import SignUpCompleteHeader  from "../../components/signup/complete/SignUpCompleteHeader";

const SignUpCompletePage: VFC = () => {
  return (
    <>
      <BaseLayout>
        <Helmet>
          <title>お申込み・無料お試し｜株式会社MareSpera</title>
        </Helmet>
        <SignUpCompleteHeader/>
        <SignUpCompleteContent/>
      </BaseLayout>
    </>
  );
};

export default SignUpCompletePage;
