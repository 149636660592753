import React, { VFC } from "react";
import "./Request.scss";

const RequestHeader: VFC = () => {
  return (
    <>
      <section className="request-header">
        <h1>資料請求</h1>
      </section>
    </>
  );
};

export default RequestHeader;
