import { FilePdfOutlined, MinusCircleOutlined, PlusOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Select, Spin }                      from "antd";
import { AxiosResponse }                                                    from "axios";
import React, { FC, useState }                                              from "react";
import { useHistory }                                                       from "react-router-dom";
import { signup }                                                           from "../../api";
import "./SignUp.scss";

interface SignUpFormError {
  target: string;
  index?: number;
}

const SignUpForm: FC = () => {
  const [corporationName, setCorporationName] = useState("");
  const [lastName, setLastName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [planId, setPlanId] = useState<number>(1);
  const [officeNames, setOfficeNames] = useState<string[]>([""]);
  const [errors, setErrors] = useState<SignUpFormError[]>([]);
  const [termsOfService, setTermsOfService] = useState(false);
  const [sendMe, setSendMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const validation = () => {
    const validations: SignUpFormError[] = [];
    if (!corporationName || corporationName === "") validations.push({target: "corporation_name"});
    if (!lastName || lastName === "") validations.push({target: "last_name"});
    if (!firstName || firstName === "") validations.push({target: "first_name"});
    if (!email || email === "") validations.push({target: "email"});
    if (!phoneNumber || phoneNumber === "") validations.push({target: "phone_number_required"});
    if (!phoneNumber || phoneNumber.length > 20) validations.push({target: "phone_number_size"});
    if (!planId || isNaN(planId)) validations.push({target: "plan"});

    officeNames.forEach((value, index) => {
      if (value === "") validations.push({target: "office_names", index: index});
    });
    if (!termsOfService) validations.push({target: "terms_of_service"});
    setErrors(validations);

    if (validations.length <= 0) {
      setLoading(true);
      signup({
        corporation: {
          name: corporationName,
          phone_number: phoneNumber
        },
        applicant: {
          last_name: lastName,
          first_name: firstName,
          email: email
        },
        contract_plan_id: planId,
        offices: officeNames.map(value => ({name: value})),
        send_self_email: sendMe
      }).then((response: AxiosResponse) => {
        history.push("/signup/complete");
      }).finally(() => setLoading(false));
    }
  };

  return (
    <>
      <Spin spinning={ loading }>
        <Form
          layout="vertical"
          className="signup-form"
        >
          <Form.Item
            className="signup-form-corporation"
            label="法人名"
            colon={ false }
            validateStatus={ errors.find(value => value.target === "corporation_name") ? "error" : undefined }
            help={ errors.find(value => value.target === "corporation_name") ? "法人名が入力されていません" : undefined }
            required
          >
            <Input
              placeholder="法人名を入力してください"
              value={ corporationName }
              onChange={ event => setCorporationName(event.target.value) }
            />
          </Form.Item>

          <Form.Item
            label="申込者氏名"
            colon={ false }
            required
          >
            <Form.Item
              className="signup-form-name"
              style={ {marginRight: "1rem"} }
              label="姓"
              colon={ false }
              validateStatus={ errors.find(value => value.target === "last_name") ? "error" : undefined }
              help={ errors.find(value => value.target === "last_name") ? "申込者の姓が入力されていません" : undefined }
            >
              <Input
                placeholder="申込者の姓を入力してください"
                value={ lastName }
                onChange={ event => setLastName(event.target.value) }
              />
            </Form.Item>
            <Form.Item
              className="signup-form-name"
              label="名"
              colon={ false }
              validateStatus={ errors.find(value => value.target === "first_name") ? "error" : undefined }
              help={ errors.find(value => value.target === "first_name") ? "申込者の名が入力されていません" : undefined }
            >
              <Input
                placeholder="申込者の名を入力してください"
                value={ firstName }
                onChange={ event => setFirstName(event.target.value) }
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            className="signup-form-email"
            label="Eメールアドレス"
            colon={ false }
            validateStatus={ errors.find(value => value.target === "email") ? "error" : undefined }
            help={ errors.find(value => value.target === "email") ? "Eメールアドレスが入力されていません" : undefined }
            required
          >
            <Input
              placeholder="Eメールアドレスを入力してください"
              value={ email }
              onChange={ event => setEmail(event.target.value) }
            />
          </Form.Item>

          <Form.Item
            className="signup-form-phone"
            label="電話番号"
            colon={ false }
            validateStatus={ errors.find(value => value.target === "phone_number_required") ? "error" : errors.find(value => value.target === "phone_number_size") ? "error" : undefined }
            help={ errors.find(value => value.target === "phone_number_required") ? "電話番号が入力されていません" : errors.find(value => value.target === "phone_number_size") ? "電話番号は20文字以内で入力してください" : undefined }
            required
          >
            <Input
              placeholder="電話番号を入力してください"
              value={ phoneNumber }
              onChange={ event => setPhoneNumber(event.target.value) }
              maxLength={ 20 }
            />
          </Form.Item>

          <Form.Item
            label="プラン"
            colon={ false }
            validateStatus={ errors.find(value => value.target === "plan") ? "error" : undefined }
            help={ errors.find(value => value.target === "plan") ? "プランが選択されていません" : undefined }
            required
          >
            <Select
              defaultValue={ 1 }
              value={ planId }
              onChange={ value => setPlanId(value) }
            >
              <Select.Option value={ 1 }>Aプラン(担当者1～3人まで)</Select.Option>
              <Select.Option value={ 2 }>Bプラン(担当者4～10人まで)</Select.Option>
              <Select.Option value={ 3 }>Cプラン(担当者11人以上)</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="事業所名"
            className="signup-form-office"
            colon={ false }
            required
          >
            <>
              {
                officeNames.map((value, index) => (
                  <>
                    <Form.Item
                      validateStatus={ errors.find(value => value.target === "office_names" && value.index === index) ? "error" : undefined }
                      help={ errors.find(value => value.target === "office_names" && value.index === index) ? "事業所名を入力してください" : undefined }
                    >
                      <Input
                        placeholder="事業所名を入力してください"
                        style={ {
                          width: officeNames.length > 1 ? "90%" : "",
                          marginRight: officeNames.length > 1 ? ".5rem" : "",
                          marginBottom: ".5rem"
                        } }
                        defaultValue={ value }
                        onChange={ event => officeNames[index] = event.target.value }
                      />
                      <>
                        {
                          officeNames.length > 1 ? (
                            <>
                              <MinusCircleOutlined
                                className="signup-form-office-delete"
                                onClick={ () => {
                                  const names: string[] = [];
                                  officeNames.splice(index, 1);
                                  officeNames.forEach(name => {
                                    names.push(name);
                                  });
                                  setOfficeNames(names);
                                } }
                              />
                            </>
                          ) : null
                        }
                      </>
                    </Form.Item>
                  </>
                ))
              }
            </>
            <Button
              type="dashed"
              onClick={ () => {
                const names: string[] = [];
                officeNames.forEach(value => {
                  names.push(value);
                });
                names.push("");
                setOfficeNames(names);
              } }
            >
              <PlusOutlined/>事業所を追加する
            </Button>
          </Form.Item>

          <Form.Item
            label="利用規約への同意"
            validateStatus={ errors.find(value => value.target === "terms_of_service") ? "error" : undefined }
            help={ errors.find(value => value.target === "terms_of_service") ? "利用規約への同意がされていません" : undefined }
            required
          >
            <Checkbox checked={ termsOfService }
                      onClick={ () => setTermsOfService(!termsOfService) }>利用規約に同意する</Checkbox>
            <Button
              type="primary"
              href="https://s3-ap-northeast-1.amazonaws.com/marespera-corporate-site/terms-of-service/TermsOfService.pdf"
              target="_blank"
            >
              <FilePdfOutlined/>利用規約はこちら(PDF)
            </Button>
          </Form.Item>

          <Form.Item>
            <Checkbox checked={ sendMe } onClick={ () => setSendMe(!sendMe) }>入力した内容を自分宛に送信する</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              onClick={ () => validation() }
            >
              <SendOutlined/>申し込む
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default SignUpForm;
