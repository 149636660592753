import React, { VFC }   from "react";
import { Helmet }       from "react-helmet-async";
import BaseLayout       from "../components/layouts/BaseLayout";
import RequestForm      from "../components/request/RequestForm";
import RequestFormTitle from "../components/request/RequestFormTitle";
import RequestHeader    from "../components/request/RequestHeader";

const RequestPage: VFC = () => {
  return (
    <>
      <BaseLayout>
        <Helmet>
          <title>資料請求｜株式会社MareSpera</title>
          <meta name="description" content="ResKa（レスカ）の資料請求は、こちらのページより必要事項をご記入のうえ、お問い合わせください。" />
          <meta name="robots" content="max-image-preview:large" />
          <link rel="canonical" href="https://reska.marespera.co.jp/request/" />
          <meta property="og:locale" content="ja_JP" />
          <meta property="og:site_name" content="株式会社MareSpera | 関わる人全てがプラスに" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="資料請求 | 株式会社MareSpera" />
          <meta property="og:description" content="ResKa（レスカ）の資料請求は、こちらのページより必要事項をご記入のうえ、お問い合わせください。" />
          <meta property="og:url" content="https://reska.marespera.co.jp/request/" />
          <meta property="og:image" content="https://marespera.co.jp/wp-content/uploads/2017/12/グループ化-8.png" />
          <meta property="og:image:secure_url" content="https://marespera.co.jp/wp-content/uploads/2017/12/グループ化-8.png" />
          <meta property="article:published_time" content="2018-10-15T06:26:27+00:00" />
          <meta property="article:modified_time" content="2021-08-12T01:36:58+00:00" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:domain" content="reska.marespera.co.jp" />
          <meta name="twitter:title" content="資料請求 | 株式会社MareSpera" />
          <meta name="twitter:description" content="ResKa（レスカ）の資料請求は、こちらのページより必要事項をご記入のうえ、お問い合わせください。" />
          <meta name="twitter:image" content="https://marespera.co.jp/wp-content/uploads/2017/12/グループ化-8.png" />
        </Helmet>
        <RequestHeader/>
        <RequestFormTitle/>
        <RequestForm/>
      </BaseLayout>
    </>
  );
};

export default RequestPage;
